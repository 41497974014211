<template>
  <MasterDetail
    formTitle="Cadastro Unidade"
    :cols="cols"
    :opts="opts"
    :resourceUrl="resourceUrl"
    descriptionProperty="unidade"
  ></MasterDetail>
</template>

<script>
import MasterDetail from "@/components/master-detail.vue";
import { mapGetters } from 'vuex';

export default {
  props: {
    selectedClient: { type: Number , default: () => null}
  },
  components: {
    MasterDetail,
  },
  computed: {
    ...mapGetters(['clientId']),
    resourceUrl: function () {
      const id = this.clientId > 0 ? this.clientId : this.$props.selectedClient;
      return `/v1/clientes/${id}/unidades`;
    },
  },
  data: function () {
    return {
      opts: {
        matriz: [
          {
            id: "Sim",
            nome: "Sim",
          },
          {
            id: "Não",
            nome: "Não",
          },
        ],
      },
      cols: [
        { key: "id", name: "ID", hideInform: true },
        {
          key: "unidade",
          name: "Unidade",
          type: this.$fieldTypes.TEXT,
        },
        {
          key: "cnpj",
          name: "CNPJ",
          type: this.$fieldTypes.CNPJ,
        },
       {
          key: "matriz",
          name: "Matriz",
          type: this.$fieldTypes.SELECT,
          rel: { to: "matriz", key: "id", name: "nome" },
        },
        {
          key: "criado_em",
          name: "Criado Em",
          type: this.$fieldTypes.DATETIME,
          hideInform: true,
        },
        {
          key: "modificado_em",
          name: "Modificado Em",
          type: this.$fieldTypes.DATETIME,
          hideInform: true,
        },
      ],
    };
  },
};
</script>
